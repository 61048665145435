import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddElementFormComponent } from './components/add-element-form/add-element-form.component';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { findIndex } from 'underscore'
import { FieldSelected } from '../../models/field-config.interface';
import { DynamicFormService } from '../../services/dynamic.service';

@Component({
  selector: 'app-dynamic-form-builder',
  templateUrl: './dynamic-form-builder.component.html'
})
export class DynamicFormBuilderComponent implements OnInit, OnChanges {
  @ViewChild('dynamicFormPreviewComponent', {static: true}) dynamicFormPreviewComponent: DynamicFormComponent | undefined;
  @Input() formDataModel: any;
  @Input() isChangeFormModel: boolean | undefined;
  @Output() returnModel = new EventEmitter<any>();

  detailsFormModel: any = {
    type: 'rows',
    inputs: []
  };

  constructor(private modalService: NgbModal, private dynamicFormService: DynamicFormService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadDataJson();
  }

  loadDataJson() {
    if (!this.formDataModel?.inputs) {
      this.formDataModel = {
        inputs: []
      };
    }
    const dataForm = this.dynamicFormService.managementDetailModelForm(this.formDataModel.inputs);
    this.detailsFormModel = dataForm.formModel;
  }

  validateForm() {
    this.dynamicFormPreviewComponent?.validate();
  }

  actionFieldSelected(field: FieldSelected) {
    const idx = findIndex(this.formDataModel.inputs, {name: field.name});
    console.log(this.formDataModel.inputs,field)
    if (field.action === 'edit') {
      const componentRef = this.modalService.open(AddElementFormComponent, {size: 'lg', centered: true});
      componentRef.componentInstance.fieldSelected = this.formDataModel.inputs[idx];
      console.log(this.formDataModel.inputs[idx])
      componentRef.componentInstance.sendField.subscribe((field: any) => {
        this.formDataModel.inputs[idx] = field;
        this.loadDataJson();
        console.log(this.formDataModel.inputs[idx])
        this.returnModel.emit(this.formDataModel);
      });
    } else if (field.action === 'delete') {
      this.formDataModel.inputs.splice(idx, 1);
      this.loadDataJson();
      this.returnModel.emit(this.formDataModel);
    }
  }

  addNewField() {
    const componentRef = this.modalService.open(AddElementFormComponent, {size: 'lg', centered: true});
    componentRef.componentInstance.sendField.subscribe((field: any) => {
      console.log(field,'field')
      this.formDataModel.inputs.push(field);
      this.loadDataJson();
      this.returnModel.emit(this.detailsFormModel);
    });
  }

}
