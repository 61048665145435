import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiEnvironment } from '../../config/api.config';
import { processQueryParams } from '../libs/util';
import { CallModel } from '../models/call.model';


@Injectable({
    providedIn: 'root',
})
export class CollectionService {

    constructor(private http: HttpClient) {
    }

    /**
     * Get All Collections
     * @param params
     */
    getAllCollections(params: any) {
        const paramsQuery = processQueryParams(params);
        return this.http.get<{count: number; rows: any[]}>(`${apiEnvironment.url}/collection`,
            {
                params: paramsQuery
            });
    }

    /**
     * Get One Collection
     * @param collectionId
     */
    getOneCollection(collectionId: string) {
        return this.http.get<any>(`${apiEnvironment.url}/collection/one/${collectionId}`);
    }

    getCollectionByUserCampaign(params?: any) {
        const paramsQuery = processQueryParams(params);
        return this.http.get<{count: number; rows: any[]}>(
            `${apiEnvironment.url}/collection/by-user`,
            {
                params: paramsQuery,
            }
        );
    }

    getOneByCampaign(collectionId: string, collectionCampaignId: string) {
        return this.http.get<any>(
            `${apiEnvironment.url}/collection/oneByCampaign`,
            {
                params: {
                    collectionId,
                    collectionCampaignId
                }
            }
        );
    }

    getCollectionByCampaignAndDocument(params?: any) {
        const paramsQuery = processQueryParams(params);
        return this.http.get<any[]>(
            `${apiEnvironment.url}/collection-management/data-by-client`,
            {
                params: paramsQuery,
            }
        );
    }

    processCollectionByClient(data: any) {
        const {externalId, id, document, phones, fullName, email, extraInfo, type, clientCollectionsAndPhones} = data;
        const result = {
            client: {externalId, id, document, phones, fullName, email, extraInfo, type},
            collections: [],
            clientCollectionsAndPhones
        };
        result.collections = data.collectionClient.map((row: any) => {
            const {collection, holder} = row;
            result.client.type = holder;
            const {collectionCampaignCollection} = collection;
            return {
                ...collection,
                collectionCampaign: (collectionCampaignCollection.length > 0 ? collectionCampaignCollection[0] : null),
                collectionClient: [{client: result.client, holder, clientId: id}]
            };
        });
        return result;
    }

    getStatusCollections(model: string) {
        return this.http.get<any[]>(
            `${apiEnvironment.url}/collection/count-status/${model}`
        );
    }

    getFilesExport(model: any) {
        return this.http.get(`${apiEnvironment.url}/collection/files-export/${model}`);
    }

    getOneFile(model: any) {
        return this.http.get(`${apiEnvironment.url}/collection/file/${model}`);
    }

    createExport(model?: any) {
        return this.http.post(`${apiEnvironment.url}/collection/file-export`, model);
    }

    createCall(model: CallModel) {
        return this.http.post<CallModel>(`${apiEnvironment.url}/collection/call`, model);
    }

    updateCall(id: string, model: CallModel) {
        return this.http.put<CallModel>(`${apiEnvironment.url}/collection/call/${id}`, model);
    }

    countFilter(model: any) {
        return this.http.post(`${apiEnvironment.url}/collection/count-filters`, model);
    }

    assignmentFiltered(model: any) {
        return this.http.post(`${apiEnvironment.url}/collection/assignment-filtered`, model);
    }

    getJobProcessAssignment() {
        return this.http.get(`${apiEnvironment.url}/collection/get-job-process-assignment`);
    }

    /**
     * Get Previews Renew Leads
     * @param filters
     */
    getPreviewsRenewLeads(filters: any) {
        return this.http.get(`${apiEnvironment.url}/collection-leads/preview-renew-leads`, {
            params: filters
        });
    }

    createJobRenewLeads(filters: any, campaignId: string) {
        return this.http.post(`${apiEnvironment.url}/collection-leads/create-job-renew-leads`, {filters, campaignId});
    }

    getStatusJobRenewLeads(id: string) {
        return this.http.get(`${apiEnvironment.url}/collection-leads/progress-renew-leads/${id}`);
    }
}
