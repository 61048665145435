<!-- start Work Process -->
<section class="section bg-light" id="creators">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="text-center mb-5">
                    <h2 class="mb-3 fw-semibold lh-base">Top Creator This Week</h2>
                    <p class="text-muted">NFTs are valuable because they verify the authenticity of a non-fungible
                        asset. This makes these assets unique and one of a kind.</p>
                </div>
            </div>
        </div><!-- end row -->
        <div class="row">
            @for(data of creatorData;track $index){
            <div class="col-xl-4 col-md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-shink-0">
                                <img src="{{data.img}}" alt="" class="avatar-sm object-fit-cover rounded" />
                            </div>
                            <div class="ms-3 flex-grow-1">
                                <a routerLink="/pages/profile">
                                    <h5 class="mb-1">{{data.title}}</h5>
                                </a>
                                <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i>
                                    {{data.price}}</p>
                            </div>
                            <div>
                                <div class="dropdown float-end" ngbDropdown>
                                    <button class="btn btn-ghost-primary btn-icon dropdown arrow-none d-block" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                        <i class="ri-more-fill align-middle fs-16"></i>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                        <li><a class="dropdown-item view-item-btn" href="javascript:void(0);">Share</a>
                                        </li>
                                        <li><a class="dropdown-item edit-item-btn" href="javascript:void(0);" data-bs-toggle="modal">Report</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        }
        </div>
    </div><!-- end container -->
</section><!-- end Work Process -->

<!-- start cta -->
<section class="py-5 bg-primary position-relative">
    <div class="bg-overlay bg-overlay-pattern opacity-50"></div>
    <div class="container">
        <div class="row align-items-center gy-4">
            <div class="col-sm">
                <div>
                    <h4 class="text-white mb-0 fw-semibold">
                        Create and Sell Your NFT's</h4>
                </div>
            </div>
            <!-- end col -->
            <div class="col-sm-auto">
                <div>
                    <a href="/marletplace/create" class="btn bg-gradient btn-danger me-1">Create
                        NFT</a>
                    <a href="/marletplace/explore" class="btn btn-secondary">Discover
                        More</a>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end cta -->