<!-- start services -->
<section class="section bg-light" id="categories">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7">
                <div class="text-center mb-5">
                    <h1 class="mb-3 ff-secondary fw-semibold text-capitalize lh-base">High demand jobs <span
                            class="text-primary">Categories</span> fratured</h1>
                    <p class="text-muted">Post a job to tell us about your project. We'll quickly match you with the
                        right freelancers.</p>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="card shadow-none text-center py-3">
                    <div class="card-body py-4">
                        <div class="avatar-sm position-relative mb-4 mx-auto">
                            <div class="job-icon-effect"></div>
                            <div class="avatar-title bg-transparent text-success rounded-circle">
                                <i class="ri-pencil-ruler-2-line fs-1"></i>
                            </div>
                        </div>
                        <a herf="javascript:void(0);" class="stretched-link">
                            <h5 class="fs-17 pt-1">IT & Software</h5>
                        </a>
                        <p class="mb-0 text-muted">1543 Jobs</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card shadow-none text-center py-3">
                    <div class="card-body py-4">
                        <div class="avatar-sm position-relative mb-4 mx-auto">
                            <div class="job-icon-effect"></div>
                            <div class="avatar-title bg-transparent text-success rounded-circle">
                                <i class="ri-airplay-line fs-1"></i>
                            </div>
                        </div>
                        <a herf="javascript:void(0);" class="stretched-link">
                            <h5 class="fs-17 pt-1">Construction / Facilities</h5>
                        </a>
                        <p class="mb-0 text-muted">3241 Jobs</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card shadow-none text-center py-3">
                    <div class="card-body py-4">
                        <div class="avatar-sm mb-4 mx-auto position-relative">
                            <div class="job-icon-effect"></div>
                            <div class="avatar-title bg-transparent text-success rounded-circle">
                                <i class="ri-bank-line fs-1"></i>
                            </div>
                        </div>
                        <a herf="javascript:void(0);" class="stretched-link">
                            <h5 class="fs-17 pt-1">Government</h5>
                        </a>
                        <p class="mb-0 text-muted">876 Jobs</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card shadow-none text-center py-3">
                    <div class="card-body py-4">
                        <div class="avatar-sm position-relative mb-4 mx-auto">
                            <div class="job-icon-effect"></div>
                            <div class="avatar-title bg-transparent text-success rounded-circle">
                                <i class="ri-focus-2-line fs-1"></i>
                            </div>
                        </div>
                        <a herf="javascript:void(0);" class="stretched-link">
                            <h5 class="fs-17 pt-1">Marketing & Advertising</h5>
                        </a>
                        <p class="mb-0 text-muted">465 Jobs</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card shadow-none text-center py-3">
                    <div class="card-body py-4">
                        <div class="avatar-sm position-relative mb-4 mx-auto">
                            <div class="job-icon-effect"></div>
                            <div class="avatar-title bg-transparent text-success rounded-circle">
                                <i class="ri-pencil-ruler-line fs-1"></i>
                            </div>
                        </div>
                        <a herf="javascript:void(0);" class="stretched-link">
                            <h5 class="fs-17 pt-1">Education & training</h5>
                        </a>
                        <p class="mb-0 text-muted">105 Jobs</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card shadow-none text-center py-3">
                    <div class="card-body py-4">
                        <div class="avatar-sm position-relative mb-4 mx-auto">
                            <div class="job-icon-effect"></div>
                            <div class="avatar-title bg-transparent text-success rounded-circle">
                                <i class="ri-line-chart-line fs-1"></i>
                            </div>
                        </div>
                        <a herf="javascript:void(0);" class="stretched-link">
                            <h5 class="fs-17 pt-1">Digital Marketing</h5>
                        </a>
                        <p class="mb-0 text-muted">377 Jobs</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card shadow-none text-center py-3">
                    <div class="card-body py-4">
                        <div class="avatar-sm position-relative mb-4 mx-auto">
                            <div class="job-icon-effect"></div>
                            <div class="avatar-title bg-transparent text-success rounded-circle">
                                <i class="ri-briefcase-2-line fs-1"></i>
                            </div>
                        </div>
                        <a herf="javascript:void(0);" class="stretched-link">
                            <h5 class="fs-17 pt-1">Catering & Tourism</h5>
                        </a>
                        <p class="mb-0 text-muted">85 Jobs</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end services -->

<!-- start cta -->
<section class="py-5 bg-primary position-relative">
    <div class="bg-overlay bg-overlay-pattern opacity-50"></div>
    <div class="container">
        <div class="row align-items-center gy-4">
            <div class="col-sm">
                <div>
                    <h4 class="text-white mb-2">Ready to Started?</h4>
                    <p class="text-white-50 mb-0">Create new account and refer your friend</p>
                </div>
            </div>
            <!-- end col -->
            <div class="col-sm-auto">
                <div>
                    <a herf="javascript:void(0);" class="btn bg-gradient btn-danger">Create Free Account</a>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end cta -->