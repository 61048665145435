import { Component, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-form-modal-delete',
  template: `
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                      (click)="activeModal.close('exit')"></button>
          </div>
          <div class="modal-body">
              <div class="mt-2 mb-5 text-center">
                  <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                             colors="primary:#405189,secondary:#f06548" style="width:90px;height:90px"></lord-icon>
                  <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>¿Está seguro que desea eliminar este registro?</h4>
                      <p class="text-muted mx-4 mb-0">Se eliminará la información en la base de datos.</p>
                  </div>
              </div>
              <div class="hstack gap-2 justify-content-end">
                  <div id="status" class="d-flex align-items-center justify-content-center" style="top:38%"
                       *ngIf="loading">
                      <div class="spinner-border text-primary avatar-xs m-auto" role="status">
                          <span class="visually-hidden">Loading...</span>
                      </div>
                  </div>

                  <button *ngIf="!loading" type="button" class="btn btn-light"
                          (click)="activeModal.close('cancel')">Cerrar
                  </button>
                  <button type="button" class="btn w-sm btn-danger" id="delete-product" (click)="localFunction()">
                      Eliminar
                  </button>
              </div>
          </div>
      </div>`,
  providers: [MatSnackBar]
})

export class ModalDeleteComponent{
  @Input() Key: any;
  @Input() parentFunction!: (id: any) =>Promise<boolean>;
  loading = false;

  constructor(public activeModal: NgbActiveModal)
  {}

  async localFunction(){
    this.loading = true;
    if(this.parentFunction){
      try{
        await this.parentFunction(this.Key);
        this.loading = false;
        this.activeModal.close('success');
      }catch (err) {
        this.loading = false;
        console.error(err);
      }
    }
  }
}
