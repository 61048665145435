import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty } from 'lodash';
import { AddElementModelForm } from '../../../../models/add-element-model-form';
import { FieldConfig } from '../../../../models/field-config.interface';
import { DynamicFormComponent } from '../../../dynamic-form/dynamic-form.component';
import { InputConfigComponent } from '../../../dynamic-form/components/input-config.component';

@Component({
  selector: 'app-add-element-form',
  templateUrl: './add-element-form.component.html'
})
export class AddElementFormComponent implements OnInit, AfterViewInit {
  @Output() sendField = new EventEmitter<any>();
  @ViewChild('dynamicFormComponent', { static: true }) dynamicFormComponent: DynamicFormComponent | undefined;
  @ViewChild('validatorsModelForm', { static: true }) validatorsModelForm: DynamicFormComponent | undefined;
  @ViewChild('inputConfig', { static: false }) inputConfig: InputConfigComponent | undefined;
  @Input() fieldSelected: any;
  formModel: any = AddElementModelForm();
  validators: any = [];
  validator: string = '';
  typeInput: string = '';
  validatorParam: any = '';
  isDisabledParams: boolean = true;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngAfterViewInit(): void {
    this.dynamicFormComponent?.getInputByName('required')?.valueChanges
      .subscribe(value => {
        value === 'true'
          ? this.addValidator('required', '')
          : this.deleteValidator('required');
      });
    this.dynamicFormComponent?.getInputByName('type')?.valueChanges
      .subscribe(value => {
        const inputModel = this.formModel.inputs.find((row: any) => row.name === 'inputType');
        this.fieldSelected = { ...this.fieldSelected, type: value };
        inputModel.hide = !(value === 'input');
      });
  }

  ngOnInit(): void {
    if (this.fieldSelected) {
      this.formModel = AddElementModelForm(this.fieldSelected);
      if (this.fieldSelected.required) {
        this.addValidator('required', '');
      }
      if (!isEmpty(this.fieldSelected.validations)) {
        this.fieldSelected.validations.map((validator: any) => {
          const values = validator.split(':');
          this.addValidator(values[0], values[1]);
        });
      }

    }
  }

  toggleWithGreeting(popover: NgbPopover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  setValidator(event: any) {
    this.validator = event.target.value;
    this.isDisabledParams = this.validator === 'required' || this.validator === 'email';
    this.typeInput = this.validator === 'pattern' ? 'text' : 'number';
  }

  getData(): FieldConfig | null {
    this.dynamicFormComponent?.validate();
    if (this.dynamicFormComponent?.form.valid) {
      let values = this.dynamicFormComponent?.form.value;
      values.required = values.required === 'true';
      values.validations = this.validators.map((validator: any) => `${validator.validator}:${validator.validatorParam}`);
      if (this.inputConfig?.configInput) {
        values = { ...values, ...this.inputConfig?.configInput };
      }
      return values;
    } else {
      return null;
    }
  }

  addValidator(validator: string, validatorParam: string) {
    this.validator = '';
    this.validatorParam = '';
    this.isDisabledParams = true;
    const validatorElement = this.validators.filter((element: any) => element.validator === validator);
    if (!isEmpty(validatorElement)) {
      this.validators.map((element: any) => {
        if (element.validator === validator) {
          element.validatorParam = validatorParam;
        }
      });
    } else {
      this.validators.push({ validator: validator, validatorParam: validatorParam });
    }
  }

  deleteValidator(validator: any) {
    this.validators = this.validators.filter((element: any) => element.validator !== validator);
  }

  deleteValidatorFn(validator: any) {
    this.validators = this.validators.filter((element: any) => element.validator !== validator);
    this.dynamicFormComponent?.setValue('required', false);
  }


  confirmDistributeRules() {
    const data = this.getData();
    if (data) {
      this.sendField.emit(data);
      this.activeModal.close();
    }
  }
}
