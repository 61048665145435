import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig, FieldSelected } from '../../models/field-config.interface';

@Component({
  selector: 'app-form-select-multiple',
  template: `
    <div class="form-group" *ngIf="group && config" [formGroup]="group">
      <label class="form-label">{{ config.label }} <strong class="text-danger"
                                                           *ngIf="config.required">*</strong></label>
      <app-management-field *ngIf="editMode" [config]="config"
                            (sendField)="sendFieldFunc($event)"></app-management-field>
      <ng-select
        [items]="config.options || []"
        bindLabel="label"
        bindValue="value"
        [formControlName]="config.name"
        [multiple]="config.inputConfig.multiple"
        [addTag]="config.inputConfig.addTag ? addTagFn : false"
        [closeOnSelect]="config.inputConfig.closeOnSelect"
        (search)="config.inputConfig.onSearch ? config.inputConfig.onSearch($event) : null"
        [ngClass]="isSubmit && field.errors ? 'is-invalid' : ''"
        (change)="sendFieldFunc($event)"
      >
      </ng-select>
      <app-form-validate *ngIf="isSubmit && field.errors" [field]="field"></app-form-validate>
    </div>

  `
})
export class FormSelectMultipleComponent implements Field, OnInit {
  // @ts-ignore
  config: FieldConfig | undefined;
  // @ts-ignore
  group: FormGroup | undefined;
  isSubmit = false;
  field: any;
  editMode = false;
  @Output() sendField: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    if (this.config) {
      if (!this.config.inputConfig) {
        this.config.inputConfig = {
          multiple: true,
          addTag: false,
          closeOnSelect: false
        };
      }
      this.field = this.group?.controls[this.config.name];
    }
  }

  addTagFn(data: string) {
    return { label: data, value: data };
  }

  sendFieldFunc(nameField: FieldSelected) {
    this.sendField.emit(nameField);
  }
}
