import { NgModule } from '@angular/core';

import {CountToComponent} from './components/count-to.component';

@NgModule({
  imports: [],
  declarations: [ CountToComponent ],
  exports: [CountToComponent],
  providers: [],
})
export class GeneralComponentsModule {
}
