import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { find, map } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {

  constructor() {
  }

  managementGeneralModelForm(data: any) {
    const {result1, result2, result3, observations, scheduleDate, scheduleTime} = data ||
    {result1: null, result2: null, result3: null, observations: null, scheduleDate: null, scheduleTime: null};
    return {
      type: 'rows',
      inputs: [
        {
          row: 1,
          type: 'select', label: 'Estado', name: 'result1', options: [],
          value: result1, required: true,
          validation: [Validators.required]
        },
        {
          row: 1,
          type: 'select', hide: true, label: 'Sub Estado', name: 'result2', options: [],
          value: result2, required: true,
          validation: [Validators.required]
        },
        {
          row: 1,
          type: 'select', hide: true, label: 'Motivo', name: 'result3', options: [],
          value: result3, required: true,
          validation: [Validators.required]
        },
        {
          row: 2,
          type: 'input', inputType: 'date', label: 'FECHA AGENDA', name: 'scheduleDate',
          value: scheduleDate, hide: true
        },
        {
          row: 2,
          type: 'input', inputType: 'time', label: 'HORA AGENDA', name: 'scheduleTime',
          value: scheduleTime, hide: true
        },
        {
          row: 3,
          type: 'textarea', label: 'Observacion ', name: 'observations',
          value: observations, required: true,
          validation: [Validators.required, Validators.maxLength(300)]
        },
      ]
    };
  }

  changeFormatModelForm(formModel: any) {
    const objectModel: any = {};
    for (const field of formModel.inputs) {
      const {name, ...values} = field;
      if (field.config) {
        if (field.type === 'select' || field.type === 'select-multiple') {
          values.options = field.config;
          delete values.config;
        }
      }
      objectModel[name] = values;
    }
    return objectModel;
  }

  managementDetailModelForm(inputs: any) {
    const inputList: any[] = [];
    const triggers: any[] = [];
    map(inputs, (value: any, key) => {
      const inputModel: any = {};
      inputModel.type = value.type;
      inputModel.placeholder = value.placeholder;
      inputModel.value = value.value ? value.value : null;
      if (value.inputType) {
        inputModel.inputType = value.inputType;
      }
      if (value.type === 'select' || value.type === 'select-multiple') {
        console.log(value.options,'list data')
        inputModel.config = value.options;
        inputModel.options = value.options.origin === 'list' ? value.options.data : [];
      }
      inputModel.label = value.label;
      inputModel.name = value.name;
      inputModel.row = value.row ? value.row : 1;
      inputModel.required = value.required || false;
      inputModel.validation = this.getValidate(value.validations);
      if (value.hide) {
        inputModel.hide = true;
        inputModel.validation = [];
        const {by, field, from, where} = value.hide;
        if (by === 'field') {
          triggers.push({
            hideField: key, field, from, where, action: ({model, result, form}: any) => {
              find(model.inputs, (input, keyInput) => {
                if (input.name === key) {
                  const hide = !(result === where.toString());
                  const formControl = form.getInputByName(inputModel.name);
                  if (formControl) {
                    if (hide) {
                      formControl.setValue('');
                      formControl.setValidators([]);
                    } else {
                      formControl.setValidators(this.getValidate(value.validations));
                    }
                  }
                  model.inputs[keyInput].hide = hide;
                }
              });
            }
          });
        }
      }
      if (value.reload) {
        const {by, field, from, where} = value.reload;
        if (by === 'field') {
          triggers.push({
            reloadField: key, field, from, where, action: ({model, result, form}: any) => {
              find(model.inputs, (input, keyInput) => {
                if (input.name === key) {
                  const formControl = form.getInputByName(inputModel.name);
                  const {origin, data, filter: filterOP} = value.options;
                  const optionsSelect = {
                    origin, data,
                    // filter: { ...this.processFilters(filterOP, form.value), companyId }
                    filter: {...this.processFilters(filterOP, form.value)}
                  };
                  formControl.setValue(null);
                }
              });
            }
          });
        }
      }
      inputList.push(inputModel);
    });
    return {
      formModel: {
        type: 'rows',
        inputs: inputList
      },
      triggers
    };
  }

  processFilters(filters: any, values: any) {
    const filterPss: any = {};
    map(filters, (filt, keyf) => {
      if ((typeof filt === 'object')) {
        if (filt.field) {
          const fieldValue = find(values, (value, keyv) => (keyv === filt.field));
          if (fieldValue) {
            filterPss[keyf] = fieldValue;
          }
        }
      } else {
        filterPss[keyf] = filt;
      }
    });
    return filterPss;
  }

  getValidate(list: any) {
    const validate: any = [];
    map(list, (criteria) => {
      const params = criteria.split(':');
      switch (params[0]) {
        case 'required':
          validate.push(Validators.required);
          break;
        case 'minlengh':
          if (params[1]) {
            validate.push(Validators.minLength(params[1]));
          }
          break;
        case 'maxlengh':
          if (params[1]) {
            validate.push(Validators.maxLength(params[1]));
          }
          break;
        case 'min':
          if (params[1]) {
            validate.push(Validators.min(params[1]));
          }
          break;
        case 'max':
          if (params[1]) {
            validate.push(Validators.max(params[1]));
          }
          break;
        case 'pattern':
          if (params[1]) {
            validate.push(Validators.pattern(params[1]));
          }
          break;
      }
    });
    return validate;
  }
}
