<!-- start Discover Items-->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="d-flex align-items-center mb-5">
                    <h2 class="mb-0 fw-semibold lh-base flex-grow-1">Discover Items</h2>
                    <a routerLink="/marletplace/explore" class="btn btn-primary">View All <i class="ri-arrow-right-line align-bottom"></i></a>
                </div>
            </div>
        </div><!-- end row -->
        <div class="row">
            @for(data of discoverData;track $index){
            <div class="col-lg-4">
                <div class="card explore-box card-animate border">
                    <div class="card-body">
                        <div class="d-flex align-items-center mb-3">
                            <img src="{{data.cardImg}}" alt="" class="avatar-xs rounded-circle">
                            <div class="ms-2 flex-grow-1">
                                <a href="javascript:void(0);"><h6 class="mb-0 fs-15">{{data.author}}</h6></a>
                                <p class="mb-0 text-muted fs-13">{{data.title}}</p>
                            </div>
                            <div class="bookmark-icon">
                                <button type="button" class="btn btn-icon heart_icon_{{data.id}}" [ngClass]="{'active': data.isIcon !== true}" data-bs-toggle="button" aria-pressed="true" (click)="activeMenu(data.id)"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                        </div>
                        <div class="explore-place-bid-img overflow-hidden rounded">
                            <img src="{{data.img}}" alt="" class="explore-img w-100">
                            <div class="bg-overlay"></div>
                            <div class="place-bid-btn">
                                <a href="javascript:void(0);" class="btn btn-success"><i class="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                            </div>
                        </div>
                        <div class="mt-3">
                            <p class="fw-medium mb-0 float-end"><i class="mdi mdi-heart text-danger align-middle"></i> {{data.likes}} </p>
                            <h5 class="text-success"><i class="mdi mdi-ethereum"></i> {{data.price}} </h5>
                            <h6 class="fs-16 mb-0"><a routerLink="/marletplace/item-details">Patterns arts &amp; culture</a></h6>
                        </div>
                    </div>
                </div>
            </div>
        }
        </div><!--end row-->
    </div><!--end container-->
</section>
<!--end Discover Items-->