<header id="page-topbar" data-scroll-header (window:scroll)="windowScroll()">
  <div class="layout-width">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box horizontal-logo">
          <a href="javascript:void(0);" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
            <span class="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="17">
                        </span>
          </a>

          <a href="javascript:void(0);" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
            <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="17">
                        </span>
          </a>
        </div>

        <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
        </button>

      </div>

      <div class="d-flex align-items-center">

        <div class="dropdown d-md-none topbar-head-dropdown header-item" ngbDropdown>
          <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  ngbDropdownToggle>
            <i class="bx bx-search fs-22"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
               aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="dropdown ms-1 topbar-head-dropdown header-item" ngbDropdown>
          <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            @if (flagvalue === undefined) {
              <img src="{{valueset}}" alt="Header Language" height="20" class="rounded">
            } @else {
              <img src="{{flagvalue}}" alt="Header Language" height="20" class="rounded">
            }
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>

            <!-- item-->
            @for (item of listLang; track $index) {
              <a href="javascript:void(0);" class="dropdown-item notify-item language py-2" data-lang="eng"
                 (click)="setLanguage(item.text, item.lang, item.flag)"
                 [ngClass]="{'active': cookieValue === item.lang}">
                <img src="{{item.flag}}" alt="user-image" class="me-2 rounded" height="18"> <span
                class="align-middle">{{ item.text }}</span>
              </a>
            }
          </div>
        </div>

        <div class="ms-1 header-item d-none d-sm-flex">
          <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  data-toggle="fullscreen" (click)="fullscreen()">
            <i class='bx bx-fullscreen fs-22'></i>
          </button>
        </div>

        <div class="ms-1 header-item d-none d-sm-flex">
          <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
            <i class='bx bx-moon fs-22' (click)="changeMode('dark')"></i>
            <i class='bx bx-sun fs-22' (click)="changeMode('light')"></i>
          </button>
        </div>

        <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
          <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" src="assets/images/users/user-dummy-img.jpg"
                                 alt="Header Avatar">
                            <span class="text-start ms-xl-2">
                                <span
                                  class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ userData.fullName }}</span>
                                <span
                                  class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{ userData.role == 0 ? 'Admin' : 'User' }}</span>
                            </span>
                        </span>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- item-->
            <h6 class="dropdown-header">Bienvenido {{userData.fullName}}!</h6>
            <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i
              class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span class="align-middle"
                                                                                   data-key="t-logout">Logout</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
