import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-load-button',
  templateUrl: './load-button.component.html',
})
export class LoadButtonComponent {
  @Output() onClickButton = new EventEmitter();
  @Input() isLoad = false;
  @Input() options = {
    class: 'btn btn-success',
    icon: 'ti-save',
    text: 'Guardar'
  };

  ngOnInit(): void {
  }

  onClick() {
    this.onClickButton.emit();
  }
}
