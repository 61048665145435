import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-footer-total-and-button',
  template: `
    <div>
      <div class="card-footer pt-2">
        <div class="d-flex justify-content-between align-items-center">
          <div class="text-disabled"> Total: {{totalRows}} </div>
          <div class="progress" style="width: 30%" *ngIf="loading">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
                 aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
          </div>
          <div>
            <button type="button" class="btn btn-light mx-2" *ngIf="showCloseButton"
                    (click)="onClickClose()">Cerrar
            </button>
            <app-load-button [isLoad]="submitted" (onClickButton)="onSubmit()"
                             [options]="btnOptions"></app-load-button>
          </div>
        </div>
      </div>
    </div>`
})

export class FooterTotalAndButtonComponent {
  @Output() submit = new EventEmitter<number>();
  @Output() close = new EventEmitter<number>();
  @Input() showCloseButton: boolean = false;
  @Input() btnOptions = {
    class: 'btn btn-primary',
    icon: 'ti-save',
    text: 'Guardar'
  }

  loading: boolean = false;
  submitted: boolean = false;
  totalRows: number = 0;

  constructor() {
  }

  setLoading(value: boolean){
    this.loading = value;
  }

  setTotal(value: number){
    this.totalRows = value;
  }

  onSubmit() {
    this.submitted = true;
    this.submit.emit();
  }

  offSubmit() {
    this.submitted = false;
  }

  onClickClose() {
    this.close.emit();
  }
}
