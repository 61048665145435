import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CallService } from "../../core/services/call.service";
import { ToastService } from "../../core/services/toast.service";

@Component({
  selector: 'app-toast-timer',
  template: `
    <div class="toast-modal">
      <div aria-live="polite" class="position-relative" aria-atomic="true">
        <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true"
             data-bs-toggle="toast" style="">

          <div class="toast-header">
            <div class="p-1 px-2 btn bg-primary rounded-circle" disabled>
              <i class="las la-phone-alt"></i>
            </div>
            <span class="fw-semibold me-auto" style="margin-left: 1%;">En Progreso</span>
            <small>Teléfono: {{ phoneNumber }}</small>
          </div>

          <div class="toast-body">
            <div class="col">
              <div class="p-2 border border-dashed rounded text-center">
                <!--                      <lord-icon _ngcontent-ng-c19750631 33="" src="https://cdn.lordicon.com/kbtmbyzy.json" trigger="loop" colors="primary:#687cfe,secondary:#ff7f5d" style="width: 90px; height: 90px;"></lord-icon>-->
                <div>
                  <p class="text-muted fw-medium mb-1">Tiempo restante:</p>
                  <h5 id="auction-time-1" class="mb-0"> {{ timer }} </h5>
                </div>
              </div>
            </div><!-- end col -->
          </div>
          <div class="toast-footer d-flex justify-content-evenly mb-2">
            <app-load-button [isLoad]="loading" (onClickButton)="hangUp()" [options]="optButton"></app-load-button>
          </div>
        </div>
      </div>
    </div>`,
  providers: [MatSnackBar]
})

export class ToastTimerComponent implements OnInit {
  @Input() phoneNumber!: any;
  @Input() timeLimit!: any;
  @Output() onHangUp = new EventEmitter<any>();

  loading = false;
  timer: string = '00:00';
  optButton = {
    class: 'btn btn-danger shadow-none',
    icon: 'las la-phone-slash',
    text: 'Colgar'
  }

  constructor(
    private callService: CallService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer() {
    let remainingTime = +this.timeLimit ?? 120;

    //Inicia un intervalo que se ejecuta cada segundo
    //con el tiempo restante -1 porque se demora un segundo en ejecutar
    //actualiza "timer" con el tiempo restante
    //si el tiempo restante es menor o igual a 0, limpia el intervalo y llama a la función hangUp
    const interval = setInterval(() => {
      remainingTime = remainingTime - 1;
      if (remainingTime <= 0) {
        clearInterval(interval);
        this.hangUp();
      }
      this.timer = this.convertSecondsToTime(remainingTime);
    }, 1000);
  }

  //Transforma el tiempo restante en formato mm:ss
  convertSecondsToTime(seconds: any) {
    // const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 3600 % 60);
    // return ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
    return ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
  }

  hangUp() {
    this.loading = true;
    this.callService.hangUpCall().subscribe({
      next: () => {
        this.loading = false;
        this.toastService.show('Llamada finalizada', {classname: 'bg-success text-white', delay: 2000});
        this.closeToast();
      },
      error: (err: any) => {
        this.loading = false;
        this.toastService.show(err, {classname: 'bg-danger text-white', delay: 2000});
        console.error(err);
      }
    });
  }

  closeToast() {
    this.onHangUp.emit();
  }

}

