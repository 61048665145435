import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiEnvironment } from '../../config/api.config';
import {jsonToParamsRequest, processQueryParams} from "../libs/util";
import { CatalogModel } from "../models/catalog.model";
import {Category} from "../models/category.model";


@Injectable({
  providedIn: 'root',
})
export class CatalogService {

  constructor(private http: HttpClient) {}

  getCatalogs(params?: any) {
    const paramsQuery = processQueryParams(params);
    return this.http.get<{ count: number; rows: CatalogModel[] }>(
      `${apiEnvironment.url}/catalog`,
      {
        params: paramsQuery,
      }
    );
  }

  getList(params?: any) {
    const paramsQuery = params ? jsonToParamsRequest(params) : null;
    return this.http.get<[Category]>(`${apiEnvironment.url}/catalog/list${paramsQuery ? `?${paramsQuery}` : ''}`);
  }

  updateCatalog(model: CatalogModel) {
    return this.http.put(`${apiEnvironment.url}/catalog/${model.id}`, model);
  }

  createCatalog(model: CatalogModel) {
    return this.http.post(`${apiEnvironment.url}/catalog`, model);
  }

  findAllDistinct(params?: any) {
    const paramsQuery = params ? jsonToParamsRequest(params) : null;
    return this.http.get<[Category]>(
      `${apiEnvironment.url}/catalog/types${paramsQuery ? `?${paramsQuery}` : ''}`
    );
  }

  deleteCatalog(id: any) {
    return this.http.delete(`${apiEnvironment.url}/catalog/${id}`);
  }
}
