const ClientLogo = [
    {
      image: 'assets/images/clients/amazon.svg',
    },
    {
      image: 'assets/images/clients/walmart.svg',
    },
    {
      image: 'assets/images/clients/lenovo.svg',
    },
    {
      image: 'assets/images/clients/paypal.svg',
    },
    {
      image: 'assets/images/clients/shopify.svg',
    },
    {
      image: 'assets/images/clients/verizon.svg',
    },
  ];
  
  export { ClientLogo };
  