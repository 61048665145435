export const environment = {
  production: true,
  title: 'DLA LAW & SERVICES',
  appName: 'DLA LAW & SERVICES',
  companyName: 'Papagayo DEV',
  url: 'https://apidlaw.suite.papagayodev.com/api/v1',
  // url: 'http://localhost:3000/api/v1',
  urlDomain: 'https://dlaw.suite.papagayodev.com',
  // urlDomain: 'http://localhost:4200',
  pathFavicon:"assets/images/favicon-dlaw.ico"
};
