import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { DynamicFieldDirective } from './components/dynamic-field.directive';
import { FormValidateComponent } from './components/form-validate/form-validate.component';
import { FormTextareaComponent } from './components/form-textarea/form-textarea.component';
import { FormRowComponent } from './containers/dynamic-form/components/form-row.component';
import { FormRadioComponent } from './components/form-radio/form-radio.component';
import { FormSelectMultipleComponent } from './components/form-select-multiple/form-select-multiple.component';
import { DynamicFormBuilderComponent } from './containers/dynamic-form-builder/dynamic-form-builder.component';
import {
  AddElementFormComponent
} from './containers/dynamic-form-builder/components/add-element-form/add-element-form.component';
import { ManagementFieldComponent } from './components/management-field/management-field.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InputConfigComponent } from './containers/dynamic-form/components/input-config.component';
import { FormCheckComponent } from './components/form-check/form-check.component';


@NgModule({
  declarations: [
    DynamicFieldDirective, DynamicFormComponent, FormInputComponent, FormSelectComponent,
    FormButtonComponent, FormTextareaComponent, FormValidateComponent,
    FormRowComponent, FormRadioComponent, FormSelectMultipleComponent,
    DynamicFormComponent,
    DynamicFormBuilderComponent,
    AddElementFormComponent,
    ManagementFieldComponent,
    InputConfigComponent,
    FormCheckComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    FormsModule,
  ],
  exports: [ DynamicFormComponent, DynamicFormComponent, DynamicFormBuilderComponent ],
})
export class DynamicFormModule {
}
