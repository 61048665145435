import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Auth Services
import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (route.data?.['rol'] && !currentUser.permissions.includes(route.data?.['rol'])) {
      this.router.navigate(['/']);
      return false;
    }
    // check if user data is in storage is logged in via API.
    if (currentUser && this.authenticationService.isLoggedIn()) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate([ '/auth/login' ], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
