<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Añadir Elemento</h4>

  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-dynamic-form #dynamicFormComponent [config]="formModel">
  </app-dynamic-form>
  <app-input-config *ngIf="fieldSelected" #inputConfig [field]="fieldSelected"></app-input-config>
  <div class="card" style="width: 100%;">
    <div class="card-header">
      Validadores
    </div>
    <div class="row">
      <div *ngFor="let validator of validators"
           class=" toast align-items-center text-primary border-0 fade show m-2"
           role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            <strong>{{validator.validator}} : {{validator.validatorParam}}</strong>
          </div>
          <button type="button" class="btn-close btn-close me-2 m-auto"
                  data-bs-dismiss="toast" aria-label="Close"
                  (click)="deleteValidatorFn(validator.validator)">
          </button>
        </div>
      </div>
    </div>

  </div>
  <button
    type="button" class="btn btn-sm btn-outline-success"
    [placement]="'left'"
    [ngbPopover]="popContent"
    [autoClose]="false" triggers="manual" #p1="ngbPopover"
    (click)="toggleWithGreeting(p1)"
  >
    Añadir Validador
  </button>
  <ng-template #popContent let-greeting="greeting">
    <div class="form-group m-2">
      <label class="form-label">Validador</label>
      <select class="form-select" (change)="setValidator($event)">
        <option value="" selected disabled>Seleccione Validador</option>
        <option value="email">email</option>
        <option value="minlengh">minlengh</option>
        <option value="maxlengh">maxlengh</option>
        <option value="pattern">pattern</option>
      </select>
    </div>
    <div *ngIf="!isDisabledParams" class="form-group m-2">
      <label class="form-label">Parametro de Validador</label>
      <input type="{{typeInput}}"
             class="form-control" [(ngModel)]="validatorParam">
      <div *ngIf='validatorParam === ""' class="invalid-feedback" style="display: block">Campo Requerido</div>
    </div>
    <button type="button" [disabled]='validatorParam === "" && !isDisabledParams'
            class="btn btn-sm btn-outline-success m-2"
            (click)="addValidator(validator, validatorParam); toggleWithGreeting(p1)">Añadir
    </button>
  </ng-template>
</div>
<div class="modal-footer">
  <button class="btn btn-success" (click)="confirmDistributeRules()">Confirmar</button>
</div>
