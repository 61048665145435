import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { find, findIndex, map, remove } from 'lodash';

@Component({
  selector: 'app-input-config',
  template: `
    <div class="card" *ngIf="configType === 'selectOptions'">
      <div class="body">
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <label for="select-origin" class="form-label">Origen</label>
              <select [(ngModel)]="configInput.options.origin"
                      class="form-select " id="select-origin" (change)="setSelectOrigin($event)">
                <option *ngFor="let option of selectOriginList" [value]="option.value">{{option.label}}</option>
              </select>
            </div>
            <div class="col-8">
              <div *ngIf="configInput?.options?.origin === 'list'">
                <div class="container">
                  <label class="form-label">Datos</label>
                  <div class="row">
                    <div class="col">
                      <input [(ngModel)]="selectOptionsDataModel.label" class="form-control"
                             placeholder="Nombre">
                    </div>
                    <div class="col">
                      <input [(ngModel)]="selectOptionsDataModel.value" class="form-control"
                             placeholder="Valor">
                    </div>
                    <div class="col d-flex">
                      <button class="btn btn-light btn-sm" (click)=selectAddDataList()>
                        <i class="fa-solid fa-plus-circle"></i> Agregar
                      </button>
                    </div>
                  </div>
                  <div *ngIf="selectOptionsDataModel.error" class="invalid-feedback" style="display: block">
                    {{selectOptionsDataModel.error}}
                  </div>
                  <div class="row mt-2">
                    <div class="col table-responsive">
                      <table class="table">
                        <thead class="table-light">
                        <th>Nombre</th>
                        <th>Valor</th>
                        <th></th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let data of configInput.options.data">
                          <td>{{data.label}}</td>
                          <td>{{data.value}}</td>
                          <td><button type="button" class="btn-close" (click)="selectDeleteDataList(data.value)"></button></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="configInput?.options?.origin === 'catalog'">
                <div class="container">
                  <label class="form-label">Filtros</label>
                  <div class="row">
                    <div class="col">
                      <select [(ngModel)]="selectCatalogFieldModel.field" class="form-select">
                        <option *ngFor="let option of selectOriginCatalogFilterList"
                                [value]="option.value">{{option.label}}</option>
                      </select>
                    </div>
                    <div class="col d-flex">
                      <button class="btn btn-light btn-sm" (click)=selectAddFilter()>
                        <i class="fa-solid fa-plus-circle"></i> Agregar filtro
                      </button>
                    </div>
                  </div>
                  <div *ngIf="selectCatalogFieldModel.error" class="invalid-feedback" style="display: block">
                    {{selectCatalogFieldModel.error}}
                  </div>
                  <div class="row">
                    <ul class="q-tree">
                      <li class="row p-2 m-1 q-connector q-transition ng-star-inserted"
                          *ngFor="let filter of selectCatalogFilter">
                        <div class="col pr-0 ng-star-inserted">
                          <input type="text" class="form-control" disabled [value]="filter.field">
                        </div>
                        <div class="col pr-0 ng-star-inserted">
                          <input type="text" class="form-control" placeholder="Valor"
                                 [value]="filter.value"
                                 (change)="setFilterValue(filter.field,$event)">
                        </div>
                        <div class="col-auto ml-auto ng-star-inserted d-flex align-items-center">
                          <button type="button" class="btn-close " (click)="selectDeleteFilterList(filter.field)"></button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})

export class InputConfigComponent implements OnInit, OnChanges{
  @Input() field: any;
  selectOriginList = [
    { label: 'Listado', value: 'list' },
    { label: 'Catálogo', value: 'catalog' }
  ];
  selectOriginCatalogFilterList = [
    { label: 'Cod', value: 'id' },
    { label: 'Cod Externo', value: 'code' },
    { label: 'Tipificación', value: 'typeCatalog' },
    { label: 'Cat Padre', value: 'idCatalog' }
  ];
  selectOptionsDataModel: any = { label: null, value: null, error: null };
  selectCatalogFieldModel: any = { field: null, error: null };
  selectCatalogFilter: any = [];
  configType: any;
  configInput: any = {};

  constructor() {
  }

  ngOnInit() {
    this.setConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setConfig();
  }

  setSelectOrigin(input: any) {
    const { value } = input.target;
    this.configInput.options.origin = value;
    if (value === 'list') {
      this.configInput.options.data = [];
    } else if (value === 'catalog') {
      this.configInput.options.filter = {};
    }
  }

  selectAddDataList() {
    const { label, value } = this.selectOptionsDataModel;
    if (!label) {
      this.selectOptionsDataModel.error = 'Nombre es requerido';
    } else if (!value) {
      this.selectOptionsDataModel.error = 'Valor es requerido';
    } else {
      this.selectOptionsDataModel.error = null;
    }
    if (!this.selectOptionsDataModel.error) {
      this.configInput.options.data.push({ label, value });
      this.selectOptionsDataModel = { label: null, value: null, error: null };
    }
  }

  selectDeleteDataList(value: any) {
    remove(this.configInput.options.data,
      (row: any) => (row.value === value));
  }

  private setConfig() {
    const { type } = this.field;
    if (type === 'select' || type === 'select-multiple') {
      const { options } = this.field;
      this.configType = 'selectOptions';
      this.configInput = { options: (options || { origin: '' }) };
      if (this.configInput.options.filter) {
        this.selectCatalogFilter = map(this.configInput.options.filter,
          (value, key) => ({ field: key, value }));
      }
    }else{
      this.configType = '';
    }
  }

  selectAddFilter() {
    const { field } = this.selectCatalogFieldModel;
    if (!field) {
      this.selectCatalogFieldModel.error = 'Seleccione un campo';
    } else if (find(this.selectCatalogFilter, (row: any) => row.field === field)) {
      this.selectCatalogFieldModel.error = 'El campa esta agregado';
    } else {
      this.selectCatalogFieldModel.error = null;
    }
    if (!this.selectCatalogFieldModel.error) {
      this.selectCatalogFilter.push({ field, value: null });
      this.processSelectFilters();
    }
  }

  setFilterValue(field: any, input: any) {
    const { value } = input.target;
    const idx = findIndex(this.selectCatalogFilter, { field });
    this.selectCatalogFilter[idx].value = value;
    this.processSelectFilters();
  }

  selectDeleteFilterList(field: any) {
    remove(this.selectCatalogFilter,
      (row: any) => (row.field === field));
    this.processSelectFilters();
  }

  private processSelectFilters() {
    this.configInput.options.filter = {};
    this.selectCatalogFilter.map((row: any) => {
      this.configInput.options.filter[row.field] = row.value;
    });
  }
}
