import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-count-to',
  template: `<span>{{ currentValue }}</span>`
})

export class CountToComponent implements OnInit {
  @Input() from = 0;
  @Input() to: number = 10;
  @Input() duration = 2000;

  currentValue: number = 0;
  intervalId: any;

  ngOnInit() {
    this.startCount();
  }

  startCount() {
    const increment = (this.to - this.from) / (this.duration / 100);
    this.currentValue = this.from;
    this.intervalId = setInterval(() => {
      this.currentValue += increment;
      if (this.currentValue >= this.to) {
        this.currentValue = this.to;
        clearInterval(this.intervalId);
      }
    }, 100);
  }
}
