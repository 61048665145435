import { Injectable } from '@angular/core';
import { AuthUser } from '../models/auth.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { apiEnvironment } from '../../config/api.config';
import { isBefore } from 'date-fns';
import { jwtDecode } from 'jwt-decode';

@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {

  user!: AuthUser;

  private currentUserSubject: BehaviorSubject<AuthUser>;
  public currentUser: Observable<AuthUser>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<AuthUser>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): AuthUser {
    return this.currentUserSubject.value;
  }

  public isLoggedIn(): boolean {
    const expiration = this.getExpiration();
    return isBefore(new Date(), expiration);
  }

  public getExpiration(): Date {
    const expiration: any = localStorage.getItem('expiresAt');
    return JSON.parse(expiration);
  }

  /**
   * Performs the auth
   * @param username username of user
   * @param password password of user
   */
  login(username: string, password: string) {
    return this.http
      .post<any>(`${apiEnvironment.url}/auth/signin`, { username, password })
      .pipe(
        map((user: any) => {
          if (user && user.token) {
            const userData: AuthUser = jwtDecode(user.token);
            const expiresAt = new Date((userData.exp ?? 0) * 1000);
            userData.token = user.token;
            localStorage.setItem('currentUser', JSON.stringify(userData));
            localStorage.setItem('token', user.token);
            localStorage.setItem(
              'expiresAt',
              JSON.stringify(expiresAt.valueOf())
            );
            this.currentUserSubject.next(userData);
            if (user.status == 2) {
              console.log('tiene...');
              return { success: true, codeError: user.codeError };
            } else {
              console.log('no...');
              return { success: true };
            }
          } else {
            return { success: false };
          }
        })
      );
  }


  /**
   * Logout the user
   */
  logout() {
    // logout the user
    // return getFirebaseBackend()!.logout();
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    this.currentUserSubject.next(null!);
  }

  /**
   * Reset password
   * @param model Model with Password and Token
   */
  resetExpiredPass(model: any) {
    return this.http.post(
      `${apiEnvironment.url}/auth/update-reset`,
      model
    );
  }


  restorePassword(model: any) {
    return this.http.post(
      `${apiEnvironment.url}/auth/recovery`,
      {
        model
      },
      {}
    );
  }
}

