import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldSelected } from "../../../models/field-config.interface";

@Component({
  selector: 'app-form-row',
  template: `
    <div class="row mb-2" *ngFor="let row of rows">
      <ng-container *ngFor="let field of row">
        <div *ngIf="!field.hide" class="col">
          <ng-container appDynamicField [editMode]="editMode" [config]="field"
                        [group]="form" [isSubmit]="isSubmit" (sendField)="fieldSelected($event)"
          ></ng-container>
        </div>
      </ng-container>
    </div>`
})
export class FormRowComponent implements OnInit {
  // @ts-ignore
  @Input() rows;
  // @ts-ignore
  @Input() form;
  // @ts-ignore
  @Input() isSubmit;
  // @ts-ignore
  @Input() editMode;

  @Output() sendField: EventEmitter<any> = new EventEmitter<any>();

  constructor() {

  }

  ngOnInit(): void {
  }

  fieldSelected(data: FieldSelected) {
    this.sendField.emit(data)
  }
}
