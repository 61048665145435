import {mapKeys} from 'lodash';

export class ValidateMessages {
  MESSAGES: any = {
    required: 'Valor requerido',
    email: 'Email no válido',
    default: 'El valor del campo no es válido',
  };

  getMessageValidate(field: any) {
    mapKeys(field.errors, (value, error) => {
      const message = this.MESSAGES[error];
      if (message) {
        return message;
      }
    });
    return this.MESSAGES.default;
  }
}
