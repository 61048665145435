import { Validators } from '@angular/forms';
import { FieldConfig } from './field-config.interface';


export function AddElementModelForm(data?: FieldConfig) {
  return {
    type: 'rows',
    inputs: [
      {
        row: 1,
        type: 'input', label: 'Nombre', name: 'name',
        required: true, validation: [ Validators.required ],
        value: data ? data.name : null
      },
      {
        row: 1,
        type: 'input', label: 'Fila', name: 'row', inputType: 'number',
        required: true, validation: [ Validators.required ],
        value: data ? data.row : null
      },
      {
        row: 2,
        type: 'input', label: 'Etiqueta', name: 'label',
        value: data ? data.label : null
      },
      {
        row: 2,
        type: 'input', label: 'Placeholder', name: 'placeholder',
        value: data ? data.placeholder : null
      },
      {
        row: 2,
        type: 'select', label: 'Requerido', name: 'required',
        required: true,
        options: [
          { label: 'SI', value: 'true' },
          { label: 'NO', value: 'false' }
        ],
        validation: [ Validators.required ],
        value: data?.required ? 'true' : 'false'
      },
      {
        row: 3,
        type: 'select', label: 'Tipo', name: 'type',
        required: true, validation: [ Validators.required ],
        options: [
          { label: 'Campo de texto', value: 'input' },
          { label: 'Selector simple', value: 'select' },
          { label: 'Area de texto', value: 'textarea' },
          { label: 'Selector múltiple', value: 'select-multiple' }
        ],
        value: data ? data.type : null
      },
      {
        row: 3,
        type: 'select', label: 'Tipo de input', name: 'inputType',
        hide: data?.type !== 'input',
        options: [
          { label: 'text', value: 'text' },
          { label: 'number', value: 'number' },
          { label: 'email', value: 'email' },
          { label: 'date', value: 'date' },
          { label: 'time', value: 'time' },
          { label: 'password', value: 'password' }
        ],
        value: data ? data.inputType : null
      }
    ]
  };
}

export function ValidatorsModelForm() {
  return {
    type: 'rows',
    inputs: [
      {
        row: 1,
        type: 'select', label: 'Validadores', name: 'validator',
        options: [
          { label: 'required', value: 'required' },
          { label: 'minlengh', value: 'minlengh' },
          { label: 'maxlengh', value: 'maxlengh' },
          { label: 'min', value: 'min' },
          { label: 'pattern', value: 'pattern' }
        ]
      },
      {
        row: 2,
        type: 'input', label: 'Parametro de validador', name: 'validatorParams',
      }
    ]
  };
}
