import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layouts/layout.component';

// Auth
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '', component: LayoutComponent, canActivate: [ AuthGuard ], children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then((m) =>
          m.DashboardModule
        )
      },
      {
        path: 'management',
        loadChildren: () => import('./views/user-management/user-management.module').then((m) =>
          m.UserManagementModule
        )
      },
      {
        path: 'configuration',
        loadChildren: () => import('./views/configuration/configuration.module').then((m) =>
          m.ConfigurationModule
        )
      },
      {
        path: 'segment',
        loadChildren: () => import('./views/segment/segment.module').then((m) =>
          m.SegmentModule
        )
      },
      {
        path: 'campaign',
        loadChildren: () => import('./views/campaign/campaign.module').then((m) =>
          m.CampaignModule
        )
      },
      {
        path: 'collection',
        loadChildren: () => import('./views/collection/collection.module').then((m) =>
          m.CollectionModule
        )
      },
      {
        path: 'report',
        loadChildren: () => import('./views/report/report.module').then((m) =>
          m.ReportModule
        )
      },
      {
        path: 'monitoring',
        loadChildren: () => import('./views/monitoring/monitoring.module').then((m) =>
          m.MonitoringModule
        )
      },
    ]
  },
  { path: 'auth', loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule) },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
