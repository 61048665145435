import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  error( err: any ) {
    this.show(err, {classname: 'bg-danger text-white', delay: 2000});
  }

  success(message: string) {
    this.show( message , {classname: 'bg-success text-white', delay: 1500});
  }

  alert(message: string){
    this.show( message , {classname: 'bg-warning text-white', delay: 2000});
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
