'use strict';

import * as moment from 'moment';
import {isNil, map} from 'lodash';
import * as _ from 'underscore';
import {
  HumanizeDuration,
  HumanizeDurationLanguage,
} from 'humanize-duration-ts/dist';
import { addMonths, lastDayOfMonth } from "date-fns";

export const formatCurrency = (value: any) => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

export const formatData = (value: string | undefined) => {
  return moment(value).utc().format('YYYY-MM-DD');
};

export const formatToDateTime = (value: string | undefined) => {
  return moment(value).utc().format('YYYY-MM-DD HH:mm:ss');
};

export const outManagementBaseRoute = () => {
  return 'icesa-schedule/outbound';
};

export const jsonToParamsRequest = (json: any) => {
  return Object.keys(json)
    .map((k) => {
      return json[k]
        ? encodeURIComponent(k) + '=' + encodeURIComponent(json[k])
        : '';
    })
    .join('&');
};

export const diffDatesInDays = (date1: string, date2: any)=> {
  const mDate1 = moment(date1);
  const mDate2 = moment(date2);
  return moment.duration(mDate1.diff(mDate2)).asDays()
}

export const strReplaceAll = (text: string, data: any) => {
  map(data, (value: any, key: any) => {
    text = text.replace(key, value);
  });
  return text;
};

export const completeZeroNumberString = (
  text: string,
  completeString: string,
  len: number
) => {
  const textLen = (text + '').length;
  const zeroCant = len - textLen;
  let newText = '';
  for (let i = 0; i < zeroCant; i++) {
    newText += completeString;
  }
  newText += text;
  return newText;
};

export const timeElapsedFormat = (date1: string, date2: any) => {
  const mDate1 = moment(date1);
  const mDate2 = moment(date2);
  const elapsed = moment.duration(mDate1.diff(mDate2));
  const sign = date1 > date2 ? '' : '-';
  const elapseTime = elapsed.asMinutes();
  return sign + '' + moment.utc(elapseTime * 1000).format('HH:mm:ss');
};

export const timeElapsedMinutes = (date1: string, date2: string) => {
  const mDate1 = moment(date1);
  const mDate2 = moment(date2);
  const elapsed = moment.duration(mDate2.diff(mDate1));
  const sign = date1 > date2 ? '' : '-';
  return sign + '' + elapsed.asMinutes();
};

export const timeElapsedSeconds = (date1: string, date2: string) => {
  const mDate1 = moment(date1);
  const mDate2 = moment(date2);
  return moment.duration(mDate2.diff(mDate1)).asMinutes();
};

export const timeElapsedHumanize = (
  date1: string,
  date2: string,
  language?: string,
  largest?: any
) => {
  const mDate1 = moment(date1);
  const mDate2 = moment(date2);
  const elapsed = moment.duration(mDate2.diff(mDate1)).asMilliseconds();
  const langService: HumanizeDurationLanguage = new HumanizeDurationLanguage();
  const humanizer: HumanizeDuration = new HumanizeDuration(langService);
  const htime = humanizer.humanize(elapsed, {
    language: language ? language : 'es',
    largest: largest ? largest : 3,
  });
  return htime + (elapsed < 0 ? ' (Atrasado) ' : '');
};

export const timeElapsedHumanizeTime = (
  date1: string,
  date2: string,
  language?: string,
  largest?: any
) => {
  const mDate1 = moment(date1);
  const mDate2 = moment(date2);
  const elapsed = moment.duration(mDate2.diff(mDate1)).asMilliseconds();
  const langService: HumanizeDurationLanguage = new HumanizeDurationLanguage();
  const humanizer: HumanizeDuration = new HumanizeDuration(langService);
  const htime = humanizer.humanize(elapsed, {
    language: language ? language : 'es',
    largest: largest ? largest : 3,
  });
  return htime + (elapsed < 0 ? '' : '');
};

export const listToArray = (list: any[], key: any) => {
  const ls = [];
  if (list && list.length > 0) {
    for (const item of list) {
      ls.push(item[key]);
    }
  }
  return ls;
};
export const hourToMinuts = (t: any) => {
  const d = new Date('0001-01-01T' + t);
  return d.getHours() * 60 + d.getMinutes();
};

export const catalogToSelectInputData = (array: any[]) => {
  const list = [];
  if (array.length > 0) {
    for (const item of array) {
      list.push({label: item.name, value: item.id, code: item.code});
    }
  }
  return list;
};

export const AddMinuts = (t: any) => {
  let minutos = t;
  minutos = minutos + t;
  return minutos;
};

export const readBase64 = (file: any) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );

    reader.addEventListener(
      'error',
      (event) => {
        reject(event);
      },
      false
    );

    reader.readAsDataURL(file);
  });
};
export const parseToJson = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};
export const processQueryParams = (data: any) => {
  const options: any = {};
  map(data, (value, key) => {
    if (!isNil(value) && value !== '') {
      options[key] = value;
    }
  });
  return options;
};

//devuelve el valor Int en segundos desde un String en formato HH:MM
export const timeStringToInt = (data: any) => {
  let timeString = data.split(':');
  let hour = parseInt(timeString[0]) * 3600;
  let minutes = parseInt(timeString[1]) * 60;
  return hour + minutes;
};

//devuelve el valor String en formato HH:MM desde un int en segundos
export const timeIntToString = (data: any) => {
  let hours = Math.trunc(data / 3600);
  let minutes = (data % 3600) / 60;
  let formatHours = hours <= 9 ? '0' + hours : hours;
  let formatMinutes = minutes <= 9 ? '0' + minutes : minutes;
  return `${formatHours}:${formatMinutes}`;
};

export const serialiceIntData = (data: any) => {
  let newData: any = {};
  _.map(data, (value: any, key: any) => {
    if (!isNaN(value)) {
      newData[key] = parseInt(value) || 0;
    } else {
      newData[key] = value;
    }
  });
  return newData;
};

export const translateCustomerType = (holderId: number)=> {
  const holdersType = ['Duplicado','Titular', 'Contacto(Garante)'];
  return holdersType[holderId];
};

export const formatDate = (date: Date) => {
  return moment(date).utc().format('YYYY-MM-DD');
}

export const sumMonthDate = (date: string, months: number) => {
  const dateUpdated = addMonths(new Date(date + ' 00:00:00'), months);
  const lastDay =lastDayOfMonth(dateUpdated)

  if(dateUpdated.getDate() > lastDay.getDate()){
    return formatDate(lastDay);
  } else {
    return formatDate(dateUpdated);
  }
}