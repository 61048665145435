import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { chunk, groupBy, map } from 'lodash';

import { FieldConfig, FieldSelected } from '../../models/field-config.interface';
import { DfConfigInterface } from '../../models/df-config.interface';

@Component({
  exportAs: 'dynamicForm',
  selector: 'app-dynamic-form',
  styleUrls: ['./dynamic-form.component.css'],
  template: `
    <form [formGroup]="form" class="form" (ngSubmit)="handleSubmit($event)">
      <ng-template [ngIf]="config.type!=='rows'">
        <ng-template [ngIf]="config.columns==1">
          <div class="row mb-2" *ngIf="config.type ==='vertical'">
            <ng-template *ngFor="let field of config.inputs">
              <div *ngIf="!field.hide" class="col">
                <ng-container appDynamicField [config]="field"
                              [group]="form" [isSubmit]="isSubmit"
                ></ng-container>
              </div>
            </ng-template>
          </div>
          <div *ngIf="config.type !== 'vertical'">
            <div class="col" *ngFor="let field of config.inputs">
              <ng-container *ngIf="!field.hide" appDynamicField [config]="field"
                            [group]="form" [isSubmit]="isSubmit"
              ></ng-container>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="config.columns  && config.columns>1">
          <div class="row mb-2">
            <div class="col-sm-{{columnSize}}" *ngFor="let list of fieldList">
              <div *ngFor="let field of list">
                <ng-container *ngIf="!field.hide"
                              appDynamicField [config]="field"
                              [group]="form" [isSubmit]="isSubmit"
                ></ng-container>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-template>
      <ng-template [ngIf]="config.type==='rows'">
        <app-form-row [editMode]="editMode" (sendField)="fieldSelected($event)" [rows]="fieldRows" [form]="form"
                      [isSubmit]="isSubmit"></app-form-row>
      </ng-template>
    </form>
  `
})
export class DynamicFormComponent implements OnChanges, OnInit {
  @Input()
  config: DfConfigInterface = {
    type: 'horizontal',
    columns: 1,
    inputs: []
  };
  @Input()
  editMode = false;
  // @ts-ignore
  form: FormGroup;
  fieldList: FieldConfig[] | any = [];
  fieldRows: [][] = [];
  columnSize = 12;
  isSubmit = false;
  lockForm = false;

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendField: EventEmitter<any> = new EventEmitter<any>();

  get changes() {
    return this.form.valueChanges;
  }

  get valid() {
    return this.form.valid;
  }

  get value() {
    return this.form.value;
  }

  setLockForm(value: boolean) {
    this.lockForm = value;
  }

  constructor(private formBuilder: FormBuilder) {
  }

  fieldSelected(nameField: FieldSelected) {
      this.sendField.emit(nameField)
  }

  ngOnInit() {
    // @ts-ignore
    this.config = {...{type: 'horizontal', ...this.config}};
    // @ts-ignore
    this.form = this.createGroup();
    if (this.config.type === 'rows') {
      const temp = groupBy(this.config.inputs, 'row');
      if (temp) {
        this.fieldRows = [];
        map(temp, (list, key) => {
          // @ts-ignore
          this.fieldRows.push(list);
        });
      }
    }
    // @ts-ignore
    else if (this.config.columns > 1) {
      this.fieldList = chunk(this.config.inputs,
        // @ts-ignore
        Math.ceil(this.config.inputs.length / this.config.columns));
      // @ts-ignore
      this.columnSize = Math.floor(12 / this.config.columns);
    } else {
      this.fieldList = this.config.inputs;
    }
  }

  reloadForm() {
    // @ts-ignore
    this.config = {...{type: 'horizontal', ...this.config}};
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      // @ts-ignore
      const controlNames = [];
      // @ts-ignore
      const configControls = [];
      this.config.inputs.map((item) => {
        controlNames.push(item.name);
        configControls.push(item);
      });
      controls
        // @ts-ignore
        .filter((control) => !controlNames.includes(control))
        .forEach((control) => this.form.removeControl(control));
      // @ts-ignore
      configControls
        .filter((control) => !controls.includes(control.name))
        .forEach((control) => {
          this.form.addControl(control.name, this.createControl(control));
        });
      if (this.config.type === 'rows') {
        const temp = groupBy(this.config.inputs, 'row');
        if (temp) {
          this.fieldRows = [];
          map(temp, (list, key) => {
            // @ts-ignore
            this.fieldRows.push(list);
          });
        }// @ts-ignore
      } else if (this.config.columns > 1) {
        this.fieldList = chunk(this.config.inputs,
          // @ts-ignore
          Math.ceil(this.config.inputs.length / this.config.columns));
        // @ts-ignore
        this.columnSize = Math.floor(12 / this.config.columns);
      } else {
        this.fieldList = this.config.inputs;
      }
    }
  }

  ngOnChanges() {
    this.reloadForm();
  }

  createGroup() {
    if (this.config.inputs) {
      const group = this.formBuilder.group({});
      this.config.inputs.forEach(control => {
        group.addControl(control.name, this.formBuilder.control(control.value, control.validation));
      });
      return group;
    } else {
      return null;
    }

  }

  createControl(config: FieldConfig) {
    const {disabled, validation, value} = config;
    return this.formBuilder.control({disabled, value}, validation);
  }

  handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.lockForm) {
      this.validate();
      this.submit.emit({valid: this.form.valid, value: this.form.value});
    }
  }

  validate() {
    if (!this.form.valid) {
      this.isSubmit = true;
      this.fieldList = chunk(this.config.inputs,
        // @ts-ignore
        Math.ceil(this.config.inputs.length / this.config.columns));
      // @ts-ignore
      this.columnSize = Math.floor(12 / this.config.columns);
    }
  }

  setDisabled(name: string, disable: boolean) {
    if (this.form.controls[name]) {
      const method = disable ? 'disable' : 'enable';
      this.form.controls[name][method]();
      return;
    }

    this.config.inputs = map(this.config.inputs, (item) => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  createObservableField(name: string) {
    if (this.form.controls[name]) {
      // @ts-ignore
      return this.form.get(name).valueChanges;
    } else {
      return null;
    }
  }

  setValue(name: string, value: any) {
    this.form.controls[name].setValue(value, {emitEvent: true});
  }

  getInputByName(name: string) {
    if (this.form.controls[name]) {
      return this.form.get(name) as FormControl;
    } else {
      return null;
    }
  }

}
