import { Injectable } from '@angular/core';
import { CatalogService } from './catalog.service';
import { map, find, isArray } from 'underscore';
import { catalogToSelectInputData } from '../libs/util';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DynamicManagementService {

  constructor(private catalogService: CatalogService) {
  }

  managementDetailModelForm(inputs: any) {
    const inputList: any[] = [];
    const triggers: any[] = [];
    map(inputs, (value: any, key) => {
      const inputModel: any = {};
      inputModel.type = value.type;
      inputModel.value = value.value ? value.value : null;
      if (value.inputType) {
        inputModel.inputType = value.inputType;
      }
      if (value.type === 'select' || value.type === 'select-multiple') {
        const {origin, data, filter: filterOP} = value.options;
        const optionsSelect = {
          origin, data,
          filter: {...this.processFilters(filterOP, {})}
        };
        this.setOptionsSelectInput(inputModel, {options: optionsSelect});
      }
      if(value.type === 'radio'){
        inputModel.options = value.options;
      }
      inputModel.label = value.label;
      inputModel.name = value.name;
      inputModel.row = value.row ? value.row : 1;
      inputModel.required = value.required || false;
      inputModel.validation = this.getValidate(value.validations);
      if (value.hide) {
        const inputTrigger = find(inputs, (input, keyInput) => (input.name === value.hide.field));
        //validar si el campo que llega tiene el valor de la condicion
        inputModel.validation = [];
        const {by, field, from, where} = value.hide;
        inputModel.hide = inputTrigger?.value !== where;
        if (by === 'field') {
          triggers.push({
            hideField: key, field, from, where, action: ({model, result, form}: any) => {
              find(model.inputs, (input, keyInput) => {
                if (input.name === value.name) {
                  const hide = !(typeof where === 'string' ? result === where : (isArray(where) ? (where.indexOf(result) > -1) : false));
                  const formControl = form.getInputByName(inputModel.name);
                  if (formControl) {
                    if (hide) {
                      formControl.setValue('');
                      formControl.setValidators([]);
                    } else {
                      formControl.setValidators(this.getValidate(value.validations));
                    }
                  }
                  model.inputs[keyInput].hide = hide;
                }
              });
            }
          });
        }
      }
      if (value.reload) {
        const {by, field, from, where} = value.reload;
        if (by === 'field') {
          triggers.push({
            reloadField: key, field, from, where, action: ({model, result, form}: any) => {
              find(model.inputs, (input, keyInput) => {
                if (input.name === key) {
                  const formControl = form.getInputByName(inputModel.name);
                  const {origin, data, filter: filterOP} = value.options;
                  const optionsSelect = {
                    origin, data,
                    // filter: { ...this.processFilters(filterOP, form.value), companyId }
                    filter: {...this.processFilters(filterOP, form.value)}
                  };
                  formControl.setValue(null);
                  this.setOptionsSelectInput(model.inputs[keyInput], {options: optionsSelect});
                }
              });
            }
          });
        }
      }
      inputList.push(inputModel);
    });
    return {
      formModel: {
        type: 'rows',
        inputs: inputList
      },
      triggers
    };
  }

  async setOptionsSelectInput(input: any, {options}: any) {
    if (options) {
      if (options.origin === 'list') {
        input.options = options.data;
      } else if (options.origin === 'catalog') {
        this.catalogService.getList(options.filter).subscribe({
          next: (data: any) => {
            input.options = catalogToSelectInputData(data);
          }
        });
      }
    }
  }

  processFilters(filters: any, values: any) {
    const filterPss: any = {};
    map(filters, (filt, keyf) => {
      if ((typeof filt === 'object')) {
        if (filt.field) {
          const fieldValue = find(values, (value, keyv) => (keyv === filt.field));
          if (fieldValue) {
            filterPss[keyf] = fieldValue;
          }
        }
      } else {
        filterPss[keyf] = filt;
      }
    });
    return filterPss;
  }

  getValidate(list: any) {
    const validate: any = [];
    map(list, (criteria) => {
      const params = criteria.split(':');
      switch (params[0]) {
        case 'required':
          validate.push(Validators.required);
          break;
        case 'minlengh':
          if (params[1]) {
            validate.push(Validators.minLength(params[1]));
          }
          break;
        case 'maxlengh':
          if (params[1]) {
            validate.push(Validators.maxLength(params[1]));
          }
          break;
        case 'min':
          if (params[1]) {
            validate.push(Validators.min(params[1]));
          }
          break;
        case 'max':
          if (params[1]) {
            validate.push(Validators.max(params[1]));
          }
          break;
        case 'pattern':
          if (params[1]) {
            validate.push(Validators.pattern(params[1]));
          }
          break;
      }
    });
    return validate;
  }
}
